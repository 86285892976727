/* You can add global styles to this file, and also import other style files */

body {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    margin: 0px;
}

.container {
    width: 1200px;
    margin: 0px auto;
}

.df-page-content {
    margin-bottom: 50px;
    margin-top: 150px;

    .df-page-title {
        .material-icons {
            margin-right: 5px;
            color: #FB9191;
        }
    }
}

@media screen and (max-width: 1220px) {
    .container {
        width: 900px !important;
    }
}

@media screen and (max-width: 920px) {
    .container {
        width: 750px !important;
    }
}

@media screen and (max-width: 768px) { 

    .container {
        width: 700px !important;
    }
}

@media screen and (max-width: 555px) { 

    .container {
        width: 320px !important;
    }

    .df-page-content {
        margin-top: 80px;
    }
}